import "./index.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ErrorBoundary } from "@sentry/react";

import { GlobalBanners } from "./components/modules/AppBanners";
import { AppUnknownExceptionViewRenderer } from "./components/modules/Error/AppUnknownExceptionView";
import { createFeatureFlagProvider } from "./features/feature-flags";
import { removedDeprecatedStorageItems } from "./helpers/storage-maintenance";
import initMonitoring from "./monitoring";
import { MixpanelProvider } from "./monitoring/mixpanel";
import EmailNotValidPage from "./pages/Setup/pages/EmailNotValidPage";
import EmailNotVerifiedPage from "./pages/Setup/pages/EmailNotVerifiedPage";
import ApolloProvider from "./providers/ApolloProvider";
import Auth0ProviderWithHistory from "./providers/Auth0ProviderWithHistory";
import { DarkModeProvider } from "./providers/DarkModeProvider";
import { WeldLoadingProvider } from "./providers/LoadingProvider";
import { ToastProvider } from "./providers/ToastProvider";
import { UserProvider } from "./providers/UserProvider";
import WeldGTMProvider from "./providers/WeldGTMProvider";
import { MaintenanceProvider } from "./providers/operations/MaintenanceProvider";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";
import {
  AppUpdater,
  appUpdaterHandleRef,
} from "./service-worker/AppUpdateToast";
import * as registerServiceWorker from "./service-worker/serviceWorkerRegistration";

initMonitoring();

console.log("Hey", window.location.origin);

async function render(content: React.ReactElement) {
  const container = document.getElementById("root");
  const root = createRoot(container!);

  const WeldFeatureFlagProvider = await createFeatureFlagProvider();

  root.render(
    <HelmetProvider>
      <MixpanelProvider>
        <DarkModeProvider>
          <WeldLoadingProvider>
            <ToastProvider>
              <Auth0ProviderWithHistory>
                <ApolloProvider>
                  <WeldGTMProvider>
                    <WeldFeatureFlagProvider>
                      <MaintenanceProvider>{content}</MaintenanceProvider>
                    </WeldFeatureFlagProvider>
                  </WeldGTMProvider>
                </ApolloProvider>
              </Auth0ProviderWithHistory>
            </ToastProvider>
          </WeldLoadingProvider>
        </DarkModeProvider>
      </MixpanelProvider>
    </HelmetProvider>,
  );
}

function App() {
  return (
    <ErrorBoundary fallback={AppUnknownExceptionViewRenderer}>
      <div className="absolute inset-0 flex flex-col">
        <GlobalBanners />
        <div className="relative w-full grow">
          <UserProvider>
            <Helmet>
              <title>WELD</title>
            </Helmet>
            <Routes />
          </UserProvider>
        </div>
      </div>
    </ErrorBoundary>
  );
}

const searchParams = new URLSearchParams(window.location.search);
const ProtectedApp = withAuthenticationRequired(App, {
  loginOptions: {
    authorizationParams: {
      screen_hint: window.location.pathname === "/signup" ? "signup" : "login",
      login_hint: searchParams.get("email") ?? undefined,
    },
  },
});

const isEmailNotValidPath =
  window.location.pathname.startsWith("/email-not-valid");
const isEmailNotVerifiedPath = window.location.pathname.startsWith(
  "/email-not-verified",
);

if (isEmailNotValidPath) {
  // Render `email not valid` page, i.e. suspicious email
  render(<EmailNotValidPage />);
} else if (isEmailNotVerifiedPath) {
  // Render `email not verified` page
  render(<EmailNotVerifiedPage />);
} else {
  // Render Weld App
  render(<ProtectedApp />);
}

// Clean up obselete local storage items
removedDeprecatedStorageItems();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);

const toastContainer = document.getElementById("app-update-toast-container");
const toastRoot = createRoot(toastContainer!);
toastRoot.render(<AppUpdater ref={appUpdaterHandleRef} />);

registerServiceWorker.register({
  checkForUpdatesInterval: 60 * 10 * 1000, // 10 minutes
  onUpdateAvailable: (onAccept) => {
    appUpdaterHandleRef.current?.showUpdateAvailableToast(onAccept);
  },
});
