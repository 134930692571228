import { useEffect, useMemo, useRef, useState } from "react";
import {
  SortingRule,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowState,
  useSortBy,
  useTable,
} from "react-table";

import { ButtonPrimitive } from "@/components/elements/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/elements/Table";
import { Input } from "@/components/primitives/input";
import cn from "@/helpers/classNames";
import { useGetModelPath } from "@/pages/ModelTool/hooks/useModelFolder";
import { RadioGroup } from "@headlessui/react";
import {
  ArrowUpIcon,
  ArrowsUpDownIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import { RowData, columns } from "./columns";
import { globalFilterFn } from "./filters";

export function DataTable(props: {
  syncs: RowData[];
  onRowClick: (
    row: RowData,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [sorting, setSorting] = useState<SortingRule<RowData>[]>([
    {
      id: "createdAt",
      desc: true,
    },
  ]);

  const [globalFilter, setGlobalFilter] = useState<string | undefined>();

  const getModelPath = useGetModelPath();

  const data = useMemo(() => {
    return props.syncs.map((sync) => {
      return {
        ...sync,
        modelPath: sync.model ? getModelPath(sync.model) : undefined,
      };
    });
  }, [props.syncs, getModelPath]);

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    setFilter: setTableFilter,
    setGlobalFilter: setTableGlobalFilter,
    state: { sortBy, globalFilter: tableGlobalFilter, filters: tableFilters },
  } = useTable<RowData>(
    {
      columns,
      data,
      initialState: {
        sortBy: sorting,
        hiddenColumns: ["createdAt"],
        globalFilter: globalFilter,
      },
      globalFilter: globalFilterFn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowState,
  );

  useEffect(() => {
    setSorting(sortBy);
  }, [sortBy]);

  useEffect(() => {
    setGlobalFilter(tableGlobalFilter);
  }, [tableGlobalFilter]);

  return (
    <div className="w-full">
      <div className="flex items-center gap-4 pb-4">
        <div className="relative flex w-full max-w-sm items-center">
          <Input
            ref={inputRef}
            placeholder="Filter syncs..."
            value={globalFilter || ""}
            onChange={(e) => setTableGlobalFilter(e.target.value)}
            size="md"
            className="w-full pl-10 text-sm"
          />
          <div className="absolute left-0 top-0 z-10 flex h-full items-center px-2">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 dark:text-gray-400" />
          </div>
          {globalFilter && globalFilter.trim().length && (
            <div className="absolute right-0 top-0 z-10 flex h-full items-center px-2">
              <ButtonPrimitive
                className="opacity-50 hover:opacity-100"
                onClick={() => {
                  setTableGlobalFilter(undefined);
                  inputRef.current?.focus();
                }}
              >
                <XMarkIcon className="h-5 w-5" />
              </ButtonPrimitive>
            </div>
          )}
        </div>
        <div className="flex gap-2">
          <StatusFilterButtons
            value={tableFilters.find((x) => x.id === "active")?.value}
            onChange={(value) => setTableFilter("active", value)}
          />
        </div>
      </div>
      <Table {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    {...header.getHeaderProps(
                      header.getSortByToggleProps({
                        style: {
                          maxWidth: header.maxWidth,
                          minWidth: header.minWidth,
                          width: header.width,
                        },
                      }),
                    )}
                  >
                    <div
                      className={cn("flex items-center whitespace-nowrap", {
                        "font-medium": header.isSorted,
                      })}
                    >
                      {header.render("Header")}
                      {header.canSort && (
                        <div
                          className={cn(
                            "ml-1 inline-flex h-4 w-4 text-gray-500 opacity-40",
                            {
                              "opacity-100": header.isSorted,
                            },
                          )}
                        >
                          {header.isSorted ? (
                            <ArrowUpIcon
                              className={cn({
                                "rotate-180 transition-transform":
                                  header.isSortedDesc,
                              })}
                            />
                          ) : (
                            <ArrowsUpDownIcon />
                          )}
                        </div>
                      )}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                onClick={(e) => props.onRowClick(row.original, e)}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps({
                        style: {
                          maxWidth: cell.column.maxWidth,
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function StatusFilterButtons(props: {
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
}) {
  return (
    <RadioGroup<"div", boolean | null>
      value={props.value ?? null}
      onChange={(value) => props.onChange(value === null ? undefined : value)}
    >
      <RadioGroup.Label className="sr-only">Choose a filter</RadioGroup.Label>
      <div className="flex items-center gap-2">
        {[
          { value: null, label: "all" },
          { value: true, label: "Active" },
          { value: false, label: "Inactive" },
        ].map((x) => (
          <RadioGroup.Option
            key={String(x.value)}
            value={x.value}
            className={({ active, checked }) =>
              cn(
                "h-10 cursor-pointer whitespace-nowrap rounded-sm focus:outline-none",
                active ? "ring-2 ring-blue-500 ring-offset-2" : "",
                checked
                  ? "border-transparent bg-blue-600 text-white hover:bg-blue-700"
                  : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:text-white",
                "flex items-center justify-center border px-3 py-3 text-sm font-medium uppercase",
              )
            }
          >
            <RadioGroup.Label as="p" className="uppercase">
              {x.label}
            </RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
